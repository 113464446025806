import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"
import FancyImage from "../../components/template-partials/global-components/fancy-image"
import VideoInfoCard from "../../components/template-partials/global-components/video-infocard"
import RelapsedRefractorySubmenu from "../../components/template-partials/relapsed-and-refractory/relapsed-and-refractory-submenu"

import { ExploreCard } from "../../components/template-partials/global-components/footer-cards"

import headingBg from "../../images/5.0-headerbg.jpg"
import headingRound from "../../images/relapse-refractory-nav-image.jpg"
import therapiesCollage from "../../images/iconcollage.png"
import mibgScan from "../../images/1.2.0_mibgscan-lg.png"
import videoScreen from "../../images/tv-orange.png"
import treatment from "../../images/5.1-treatment.jpg"

//Cool! Export your sass Vars from a top line and use them in JS!
import * as SassVars from "../../components/variables.module.scss"
import VideoPlayer from "../../components/VideoPlayer"

const RelapsedRefratoryIndex = () => {
  return (
    <Layout>
      <SEO
        title="What is Relapsed/Refractory Neuroblastoma?"
        description="Relapsed/refractory neuroblastoma can present additional challenges on your child’s treatment journey. Learn what combination of therapies your child’s treatment may include."
      />

      <InteriorPageHeader
        backgroundImage={headingBg}
        roundImage={headingRound}
        alt="What is Relapsed/Refractory Neuroblastoma?"
        id="relapsed-and-refractory_header_image"
      >
        <h1 className="section__heading section__heading--green h1">
          What is Relapsed/Refractory Neuroblastoma?
        </h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`Relapsed and Refractory`}>
              <RelapsedRefractorySubmenu />
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons />
              </div>
            </div>
            <div className="row">
              <div className="columns">
                <p>
                  Research has led to great strides in neuroblastoma survival.
                  However, roughly 10% to 15% of children with{" "}
                  <Link
                    rel="glossary"
                    to="/resources/glossary/#high-risk-neuroblastoma"
                  >
                    high-risk neuroblastoma
                  </Link>{" "}
                  do not respond to initial treatment (
                  <Link
                    rel="glossary"
                    to="/resources/glossary/#refractory"
                  >
                    refractory
                  </Link>
                  ). In addition, about 40% to 50% of children with
                  neuroblastoma have cancer that returns after treatment
                  (relapse).{" "}
                </p>
                <p>
                  Learning that your child’s neuroblastoma is relapsed or
                  refractory may bring about strong emotions such as disbelief,
                  betrayal, or sorrow. It is important to acknowledge these
                  feelings and talk to your child’s oncologist about the best
                  treatment options for your child.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row row--inner">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">
              Understanding Relapsed/Refractory Neuroblastoma
            </h3>
            <p>
              Hear from a pediatric oncologist and nurse practitioner as they
              discuss treatment and hope during this phase of your child’s
              journey.
            </p>
            <VideoPlayer
              embedId="761928329"
              title="Understanding Relapsed/Refractory Neuroblastoma"
            />
          </div>
        </div>

        <div className="row row--inner lg_mt-3 mt-3">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">
              Treatment for relapsed/refractory high-risk neuroblastoma{" "}
            </h3>
            <p>
              Your child’s treatment for{" "}
              <Link rel="glossary" to="/resources/glossary/#relapse">
                relapsed
              </Link>{" "}
              or{" "}
              <Link rel="glossary" to="/resources/glossary/#refractory">
                refractory
              </Link>{" "}
              <Link
                rel="glossary"
                to="/resources/glossary/#high-risk-neuroblastoma"
              >
                {" "}
                high-risk neuroblastoma
              </Link>{" "}
              will be very personalized. The therapies they will receive depend
              on multiple factors, including:{" "}
            </p>
            <ul className="ul--dotted">
              <li>Previous neuroblastoma treatment</li>
              <li>Length of time since the last treatment</li>
              <li>How much the neuroblastoma has spread </li>
            </ul>
          </div>
          <div className="columns text-center large-text-right small-12 large-4">
            <FancyImage
              url={treatment}
              alt="Treatment for relapsed/refractory high-risk neuroblastoma"
            />
          </div>
        </div>
      </Section>

      <Section style={{ backgroundColor: SassVars.paleBlue }}>
        <div className="row row--inner align-justify">
          <div className="columns text-center large-text-left small-12 large-3">
            <img
              src={therapiesCollage}
              style={{ maxWidth: `220px` }}
              alt="Relapsed or refractory neuroblastoma therapies may include chemotherapy, antibody therapy, and MIBG therapy"
            />
          </div>
          <div className="columns small-12 large-8">
            <h3 className="h2 tc-barney jost-med">
              Therapies for relapsed/refractory high-risk neuroblastoma{" "}
            </h3>
            <p>
              Often,{" "}
              <Link rel="glossary" to="/resources/glossary/#relapse">
                relapsed
              </Link>{" "}
              neuroblastoma in children with{" "}
              <Link
                rel="glossary"
                to="/resources/glossary/#high-risk-neuroblastoma"
              >
                high-risk disease
              </Link>{" "}
              is harder to treat. Your child’s oncologist may recommend an
              investigational therapy being tested as part of a{" "}
              <Link
                rel="glossary"
                to="/resources/glossary/#clinical-trials"
              >
                clinical trial
              </Link>
              .{" "}
              <Link to="/resources/downloadable-resources/#online">
                To search for clinical trials, go to our Resources & Support
                page where you can find a link to a helpful clinical trial
                search tool.
              </Link>
            </p>
            <p>
              Your child’s oncologist will select a treatment based on your
              child’s individual circumstances. Relapsed or{" "}
              <Link rel="glossary" to="/resources/glossary/#refractory">
                refractory
              </Link>{" "}
              therapies may include:{" "}
            </p>
            <ul className="ul--dotted">
              <li>
                <Link
                  rel="glossary"
                  to="/resources/glossary/#chemotherapy"
                >
                  Chemotherapy
                </Link>
              </li>
              <li>
                <Link
                  rel="glossary"
                  to="/resources/glossary/#antibody-therapy"
                >
                  Antibody therapy
                </Link>
              </li>
              <li>
                Investigative{" "}
                <Link
                  rel="glossary"
                  to="/resources/glossary/#mibg-therapy"
                >
                  MIBG (1-<sup>131</sup> meta-iodobenzylguanidine) therapy
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </Section>

      <Section>
        <div className="row row--inner">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">
              Additional relapsed/refractory therapies
            </h3>
            <p>
              Neuroblastoma survival has improved over the years. Researchers
              continue to work hard to improve survival and decrease{" "}
              <Link
                rel="glossary"
                to="/resources/glossary/#long-term-side-effect"
              >
                long-term side effects
              </Link>{" "}
              compared to the currently available neuroblastoma treatments. They
              are able to do this by testing innovative new treatments through{" "}
              <Link
                rel="glossary"
                to="/resources/glossary/#clinical-trials"
              >
                clinical trials
              </Link>
              .{" "}
              <Link
                rel="glossary"
                to="/resources/glossary/#mibg-therapy"
              >
                MIBG therapy
              </Link>{" "}
              is one of the innovative new treatments used in clinical trials
              for children with{" "}
              <Link rel="glossary" to="/resources/glossary/#relapse">
                relapsed
              </Link>
              /
              <Link rel="glossary" to="/resources/glossary/#refractory">
                refractory
              </Link>{" "}
              neuroblastoma.
            </p>
            <p>
              MIBG may also be used by your child’s healthcare team to scan the
              body for neuroblastoma tumors. The{" "}
              <Link
                rel="glossary"
                to="/resources/glossary/#mibg-or-meta-iodobenzylguanidine-scans"
              >
                MIBG scan
              </Link>{" "}
              uses a different type of radioactive iodine and is given at a much
              lower dose.{" "}
            </p>
          </div>
        </div>
        <div className="row row--inner align-justify lg_mt-4 mt-4 align-middle">
          <div className="columns text-center small-12 large-4">
            <img
              src={mibgScan}
              style={{ maxWidth: `220px` }}
              alt="MIBG is a substance combined with radioactive iodine to deliver targeted radiation therapy to tumor cells through an IV"
            />
          </div>
          <div className="columns small-12 large-8 mt-4">
            <h4 className="h4 tc-viridian jost-med">What is MIBG therapy? </h4>
            <ul className="ul--dotted">
              <li>
                MIBG is a substance that is combined with a radioactive iodine
                (I-131)
              </li>
              <li>
                When a child receives MIBG treatment, the I-131 MIBG delivers
                targeted radiation therapy to tumor cells through an IV
              </li>
              <li>
                The radiation is used to destroy cancer cells, while sparing
                normal, healthy cells
              </li>
              <li>
                The dose is much higher than when a child receives an MIBG scan
              </li>
            </ul>
          </div>
        </div>
        <div className="row row--inner align-justify lg_mt-4 mt-4">
          <div className="columns">
            <h4 className="h4 tc-viridian jost-med">
              How is MIBG therapy given?{" "}
            </h4>
            <p>
              MIBG therapy requires a hospital stay in a special lead-lined room
              because of the high level of radiation used. Your child will stay
              in the special room until the radiation has reached a safe level.
              Most children feel well during MIBG therapy; however, they must
              stay in their room to minimize radiation exposure to other people.
            </p>
            <p>
              Only select hospitals across the country are set up with these
              special rooms for MIBG therapy. If MIBG therapy is an option for
              your child, the hospital will work very closely with your family
              and provide detailed education.{" "}
            </p>
          </div>
        </div>
      </Section>

      {/* <Section>
        <VideoInfoCard gtmCat="External Link" gtmAction="Click" gtmLabel="Video Neuroblastoma Treatment Options" link={`https://www.youtube.com/channel/UCPf2wMnFK0YOZ2idAwqzWKA/videos`} text="Watch videos about neuroblastoma treatment options from a recent parent conference." img={videoScreen}/>
      </Section> */}

      <InteriorPageFooter>
        <ExploreCard
          single="true"
          title="How can you help your child when they experience side effects of treatment?"
          lead="Learn how to help manage side effects related to treatment in the Supportive Care section."
          url="/supportive-care/"
        />
      </InteriorPageFooter>
    </Layout>
  )
}

export default RelapsedRefratoryIndex
